/**
 * 验证手机号码
 * @param {string} args   手机号字符串
 * @return {object} state: 验证结果  msg: 验证释义
 * */
export function validatePhone(args: string): { state: boolean; msg: string } {
  if (!args || args.length === 0) {
    return {
      state: false,
      msg: "请输入电话号码",
    };
  }

  if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(args)) {
    return {
      state: false,
      msg: "请输入正确的电话号码",
    };
  }

  return { state: true, msg: "" };
}

/**
 * 验证座机号码
 * @param {string} args   座机号字符串
 * @return {object} state: 验证结果  msg: 验证释义
 * */

export function validateFixedPhone(
  args: string
): { state: boolean; msg: string } {
  if (!/^(\d{7,8})?$/.test(args)) {
    return {
      state: false,
      msg: "请输入正确的座机号码",
    };
  }

  return { state: true, msg: "" };
}

/**
 * 验证区号
 * @param {string} args   区号字符串
 * @return {object} state: 验证结果  msg: 验证释义
 * */

export function validateAreaCode(
  args: string
): { state: boolean; msg: string } {
  if (!/^(\d{3,4})?$/.test(args)) {
    return {
      state: false,
      msg: "请输入正确的区号",
    };
  }

  return { state: true, msg: "" };
}

/**
 * 验证码
 */
export function validateNum(arg: string): { state: boolean; msg: string } {
  if (!/^[0-9]*$/.test(arg + "") || (arg + "").length !== 6) {
    return { state: false, msg: "请输入6位纯数字的验证码" };
  }
  return { state: true, msg: "" };
}

/**
 *简单验证身份证号
 * @param {string} args     身份证号字符串
 * @return {object} state: 验证结果     msg: 验证释义
 * */
export function validateID(args: string): { state: boolean; msg: string } {
  if (!args || args.length === 0) {
    return {
      state: false,
      msg: "请输入身份证号",
    };
  }

  if (!/^\d{17}(\d|x)$/i.test(args)) {
    return {
      state: false,
      msg: "请输入正确的身份证号",
    };
  }

  return { state: true, msg: "" };
}

/**
 *验证密码格式，长度为6-18位的字母数字组合
 * @param {string} args     密码字符串
 * @return {object} state: 验证结果    msg: 验证释义
 * */
export function validatePassword(
  args: string
): { state: boolean; msg: string } {
  if (!args || args.length === 0) {
    return {
      state: false,
      msg: "请输入密码",
    };
  }

  if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/.test(args)) {
    return {
      state: false,
      msg: "请输入6-18位的字母数字组合",
    };
  }

  return { state: true, msg: "" };
}

/**
 * 校验姓名格式，为中文
 * @param {string} args    姓名字符串
 * @return {object}  state: 验证结果   msg: 验证释义
 * */
export function validateName(args: string): { state: boolean; msg: string } {
  if (!args || args.length === 0) {
    return {
      state: false,
      msg: "请输入姓名",
    };
  }

  if (!/^[\u4e00-\u9fa5]+([·•][\u4e00-\u9fa5]+)*$/.test(args)) {
    return {
      state: false,
      msg: "请输入中文姓名",
    };
  }

  return { state: true, msg: "" };
}

/**
 * 校验身份证有效期格式，为20181007-20381007
 * @param {string}  args   有效期字符串
 * @return {object} state: 验证结果   msg: 验证释义
 * */
export function validateIDTerm(args: string): { state: boolean; msg: string } {
  if (!args || args.length === 0) {
    return {
      state: false,
      msg: "请输入身份证有效期",
    };
  }

  if (!/^\d{8}-\d{8}$/.test(args) && !/^\d{8}-长期$/.test(args)) {
    return {
      state: false,
      msg: "请输入正确的有效期格式",
    };
  }

  return { state: true, msg: "" };
}

/**
 *简单验证邮箱地址
 * @param {string} args     邮箱地址字符串
 * @return {object} state: 验证结果     msg: 验证释义
 * */
export function validateEmail(args: string) : {state: boolean, msg: string} {
  if (!args || args.length === 0) {
      return {
          state: false,
          msg: '请填写邮箱地址！',
      }
  }

  if (!/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(args)) {
      return {
          state: false,
          msg: '请填写正确的邮箱地址！',
      }
  }

  return {state: true, msg: ''}
}

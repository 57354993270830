import styled from "styled-components";

export const Row = styled.div`
  display: flex;
`;

export const Column = styled(Row)`
  flex-direction: column;
  width: 100%;
`;

export const ColumnCenter = styled(Row)`
  flex-direction: column;
  align-items: center;
`;

export const RowCenter = styled(Row)`
  align-items: center;
`;

export const FlexCenter = styled(ColumnCenter)`
  justify-content: center;
`;

export const FlexFill = styled(Row)`
  flex: 1;
  flex-direction: column;
`;

export const CustomWrap = styled(FlexFill)`
justify-content:space-between;
display:flex;
`


export const GrayBg = styled.div`
  background-color: #efefef;
  padding: 0 0.16rem;
`;

export const WhiteBg = styled.div`
  background-color: #fff;
  padding: 0 0.16rem 0.36rem;
`;

export const TextWrap = styled(GrayBg)`
  padding-top: 0.18rem;
  padding-bottom: 0.17rem;
  font-size: 0.13rem;
  color: #777;
  line-height: 0.17rem;
`;

export const ContentWrap = styled(WhiteBg)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ErrorWrap = styled.div`
  background: rgba(246, 202, 80, 0.5);
  color: #bd3434;
  font-size: 0.15rem;
  line-height: 0.24rem;
  padding: 0.1rem 0.16rem;
`;

export const QrTitle = styled.div`
  color: #121212;
  font-size: 0.15rem;
  margin-bottom: 0.1rem;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #f3f3f3ff;
`;

export const BtnWrap = styled.div`
  .ant-btn{
    height: 54px;
    padding: 4px 15px;
    font-size: 20px;
    border-radius: 30px;
    color: #fff;
    background: #1789AD;
    border:none;
    width:150px;
}
`;

export const CopyRight = styled.div`
text-align:center;
margin-top:10px;
font-size:14px;
`


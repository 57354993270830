import React from "react";
import { BrowserRouter } from "react-router-dom";
import { routerRef } from "./utils/router";
import Index from './pages';
import "./app.css";

const config = require('./config');

function App() {

  return (
    <div className="App">
      {
        <BrowserRouter basename={config.basename} ref={routerRef}>
          <Index />
        </BrowserRouter>
      }
    </div>
  );
}

export default App;

import React, { useMemo } from "react";
import { ColumnCenter, RowCenter } from "../../styled";
import { HeaderWrap, HeaderItem, HeaderText, Line } from "../CCSS/styled";
import { ColorBar } from "./component/ColorBar";
import { useStarReadingData } from "./action";
import { Tabs } from "antd";
import { PdfView } from "../../../components/PdfView";
import { ScoreList } from "./component/ScoreList";
import { SubTitle } from "./styled";
import { DetailList } from "./component/DetailList";
import LoadingPage from "../../../components/loading";
import { demoColor } from "../CCSS";

const { TabPane } = Tabs;

export const StarReading: React.FC<{ type: string }> = ({ type }) => {
  const { loading, starReadingData } = useStarReadingData(type);

  const mapHeaderArr = [
    {
      label: "测试日期",
      value: starReadingData.test_date,
    },
    {
      label: "测试时长(minutes)",
      value: starReadingData.test_duration,
    },
  ];

  const scaledScore = useMemo(() => {
    const item = starReadingData.star_reading_scores.find(
      (v) => v.short_desc === "SS"
    );
    if (item) {
      return item.item_score;
    }
    return 0;
  }, [starReadingData.star_reading_scores]);

  return (
    <div>
      <Tabs onChange={(key) => {}} type="card" size="large">
        <TabPane tab="CCSS报告详情" key="1">
          <ColumnCenter>
            <HeaderWrap style={{ marginTop: 10 }}>
              {mapHeaderArr.map((v, i) => (
                <HeaderItem
                  key={i}
                  style={i % 2 === 0 ? {} : { textAlign: "right" }}
                >
                  <span>{v.label}</span>
                  <HeaderText style={i === 1 ? { fontWeight: "bolder" } : {}}>
                    {v.value}
                  </HeaderText>
                </HeaderItem>
              ))}
            </HeaderWrap>
            <Line></Line>

            <ColorBar
              benchmark={starReadingData.district_benchmark}
              num={scaledScore}
              grade={starReadingData.grade}
            />
            <Line></Line>

            <SubTitle>Star Reading测试指标分值</SubTitle>
            <ScoreList data={starReadingData.star_reading_scores} />
            <Line></Line>
            <SubTitle>Star Reading各项技能测试得分</SubTitle>
            <ScoreList
              data={starReadingData.literature_scores}
              subTitle="Literature"
              bolder
            />
            <ScoreList
              data={starReadingData.information_text_scores}
              subTitle="Informational Text"
              bolder
            />
            <ScoreList
              data={starReadingData.language_scores}
              subTitle="Language"
              bolder
            />
            <Line></Line>
            <SubTitle style={{ marginBottom: 20 }}>
              Star Reading报告详情
            </SubTitle>
            {starReadingData.report_details.map((v, i) => (
              <DetailList key={i} {...v} />
            ))}
          </ColumnCenter>
        </TabPane>
        <TabPane tab="CCSS知识点筛查地图" key="3">
          {starReadingData.simple_pdf_url && (
            <div>
              <div
                style={{
                  width: 1100,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <RowCenter>
                  {demoColor.map((v, i) => (
                    <RowCenter style={{ margin: "0 8px" }} key={i}>
                      <span
                        style={{
                          backgroundColor: v.color,
                          width: 18,
                          height: 18,
                          display: "inline-block",
                          borderRadius: 5,
                          marginRight: 3,
                        }}
                      />
                      {v.text}
                    </RowCenter>
                  ))}
                </RowCenter>
              </div>
              <PdfView pdfUrl={starReadingData.simple_pdf_url} />
            </div>
          )}
        </TabPane>
      </Tabs>
      <LoadingPage isLoading={loading} />
    </div>
  );
};

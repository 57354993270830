import styled from "styled-components";
import { Row } from "../../styled";

export const Wrap = styled.div`
  .ant-table-wrapper {
    width: 100%;
    .ant-table-content {
      table {
        /* width:; */
        .ant-table-tbody {
          .ant-table-cell {
            padding: 0;
            word-break: break-word;
          }
        }
      }
    }
    .my-ant-table-cell {
      padding: 12px 8px;
      &.green {
        background-color: green;
      }
      &.yellow {
        background-color: yellow;
      }
      &.red {
        background-color: red;
      }
    }
  }
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1100px;
`;
export const Title = styled.h2`
  margin-top: 20px;
  font-size: 26px;
  font-weight: bolder;
  text-align: center;
  color: #1789ad;
`;

export const BlockTitle = styled(Title)`
  margin: 0;
  margin-bottom: 20px;
  font-size: 30px;
`;

export const HeaderItem = styled.div`
  width: 50%;
  font-size: 18px;
`;
export const HeaderText = styled.span`
  color: #1789ad;
  font-size: 28px;
  margin-left: 10px;
`;

export const TableWrap = styled(Row)`
  margin-bottom: 30px;
  align-items: center;
`;

export const TableTitle = styled(Row)`
  color: #1789ad;
  font-size: 20px;
  margin-right: 10px;
  width: 120px;
`;

export const Line = styled.div`
  height: 3px;
  background-color: #eee;
  margin: 40px 0;
  width: 100%;
`;

export const BlockWrap = styled(Row)`
  justify-content: center;
`;

export const BlockItem = styled.div`
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #eee;
  margin: 0 40px;
`;
export const SubTitle = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  color: #1789ad;
  text-align: center;
`;

import React, { useMemo } from "react";
import { ColumnCenter, RowCenter } from "../../styled";
import { HeaderWrap, HeaderItem, HeaderText, Line } from "./styled";
import { MapTable } from "./component/MapTable";
import { useMapUrl } from "./action";
import { Tabs } from "antd";
import { PdfView } from "../../../components/PdfView";
import LoadingPage from "../../../components/loading";
const { TabPane } = Tabs;

export const demoColor = [
  {
    color: "green",
    text: "测试通过",
  },
  {
    color: "yellow",
    text: "需要加强",
  },
  {
    color: "red",
    text: "需要干预",
  },
  {
    color: "gray",
    text: "未测试",
  },
];

export const Map: React.FC<{ type: string }> = ({ type }) => {
  const { mapData, loading } = useMapUrl(type);
  // const mapOption1 = {
  //   tooltip: {
  //     trigger: "axis",
  //     axisPointer: {
  //       // 坐标轴指示器，坐标轴触发有效
  //       type: "line", // 默认为直线，可选为：'line' | 'shadow'
  //     },
  //   },
  //   xAxis: {
  //     type: "category",
  //     data: mapData.option1X,
  //   },
  //   yAxis: {
  //     type: "value",
  //   },
  //   series: [
  //     {
  //       data: mapData.option1Y,
  //       type: "line",
  //     },
  //   ],
  // };

  const mapHeaderArr = [
    {
      label: "总分",
      value: mapData.rit || "-",
    },
    {
      label: "测试时长(minutes)",
      value: mapData.duration || "-",
    },
    {
      label: "测试日期",
      value: mapData.date || "-",
    },
    {
      label: "Grade",
      value: mapData.grade || "-",
    },
  ];

  return (
    <div>
      <Tabs onChange={(key) => {}} type="card" size="large">
        <TabPane tab="CCSS报告详情" key="1">
          <ColumnCenter>
            <HeaderWrap style={{ marginTop: 10 }}>
              {mapHeaderArr.map((v, i) => (
                <HeaderItem
                  key={i}
                  style={i % 2 === 0 ? {} : { textAlign: "right" }}
                >
                  <span>{v.label}</span>
                  <HeaderText style={i === 1 ? { fontWeight: "bolder" } : {}}>
                    {v.value}
                  </HeaderText>
                </HeaderItem>
              ))}
            </HeaderWrap>
            {/* <Line></Line>
            <div>
              <SubTitle
                style={{
                  marginTop: 0,
                  color: "#333",
                  marginBottom: 0,
                }}
              >
                总分变化趋势
              </SubTitle>
              <ReactEcharts
                option={
                  mapOption1 as echarts.EChartOption<
                    echarts.EChartOption.Series
                  >
                }
                theme="Imooc"
                style={{ height: "350px", width: "1200px" }}
              />
            </div> */}
            <Line></Line>
            <MapTable
              achievement_above_mean={mapData.achievement_above_mean}
              flesch_kincaid_grade_level={mapData.flesch_kincaid_grade_level}
              growth_goals_date={mapData.growth_goals_date}
              lexile_score={mapData.lexile_score}
              sub_domains_info={mapData.sub_domains_info}
            />
            {mapData.map_growth_pic_url && (
              <div style={{ width: "1100px" }}>
                <img src={mapData.map_growth_pic_url} alt="" />
              </div>
            )}
            <Line></Line>
            <PdfView pdfUrl={mapData.map_instructional_report_pdf_url} />
            {/* <PdfView pdfUrl={'/test.pdf'} /> */}
          </ColumnCenter>
        </TabPane>
        <TabPane tab="CCSS知识点筛查图谱" key="2">
          {mapData.url && (
            <div>
              <div
                style={{
                  width: 1100,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <RowCenter>
                  {demoColor.map((v, i) => (
                    <RowCenter style={{ margin: "0 8px" }} key={i}>
                      <span
                        style={{
                          backgroundColor: v.color,
                          width: 18,
                          height: 18,
                          display: "inline-block",
                          borderRadius: 5,
                          marginRight: 3,
                        }}
                      />
                      {v.text}
                    </RowCenter>
                  ))}
                </RowCenter>
              </div>
              <PdfView pdfUrl={mapData.url} />
            </div>
          )}
        </TabPane>
      </Tabs>
      <LoadingPage isLoading={loading} />
    </div>
  );
};

/**
 * loading加载组件
 * */

import React from "react";
import { Loading } from "./styled";

interface Props {
  isLoading: boolean;
}

const LoadingPage = (props: Props) => {
  const { isLoading } = props;
  if (isLoading) {
    return <Loading size="large" spinning={true} />;
  }
  return null;
};

export default LoadingPage;

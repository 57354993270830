import React, { useEffect, useState } from "react";
// import Pdf from "./Pdf";
import Login from "./Login";
import Reset from "./ResetPassword";
import IPicture from "./IPicture";
import Evaluate from "./Evaluate";

import { useHistory, Switch, Route } from "react-router";
import {  getCookieObj, useScrollTop } from "../utils/common";
import { setItem, StoreEnum } from "../utils/store";
import LoadingPage from "../components/loading";


const routerArr = [
  // { path: `pdf`, component: Pdf }, 
  { path: 'login', component: Login },
  { path: 'reset', component: Reset },
  { path: 'i-picture', component: IPicture },
  {path:'evaluate',component:Evaluate}
];

export default () => {

  useScrollTop();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data = getCookieObj();
    console.log(1111, data);
    setItem(StoreEnum.SESSION, data.csrftoken || '');
    setItem(StoreEnum.PHONE, data.user_phone_number || '');
    setLoading(false);
  }, [])

  return (
      <Switch>
        {
          loading ?
            <LoadingPage isLoading={loading} /> :
            routerArr.map((v, i) => (
              <Route key={i} path={`/${v.path}`} component={v.component} />
            ))
      }
      </Switch>
  );
};
import styled from "styled-components";
import { Spin } from "antd";

export const Loading = styled(Spin)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color ease 0.3s;
`;

import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  width: 100%;
  .ant-menu {
    .ant-menu-inline .ant-menu-item::after {
      border-right-color: #1789ad;
    }
    .ant-menu-submenu-selected {
      color: #1789ad;
    }
    .ant-menu-submenu {
      ul {
        li.ant-menu-item {
          line-height: 48px;
          height: 48px;
          font-size: 15px;
          padding-left:40px !important;
        }
      }
    }
  }
`;

export const RightWrap = styled.div`
  flex: 1;
  padding: 30px;
  height:90vh;
  overflow-y:auto;
`;

export const Title = styled.div`
  font-size: 22px;
  margin-bottom: 30px;
  margin-top:40px;
  margin-left:10px;
  color:#1789AD;
`;

import styled from "styled-components"

export const InputWrap = styled.div`
position: relative;
margin-bottom:20px;
.ant-input{
    height:54px;
    width:280px;
    padding:0;
    color: #1888AC;
    font-size: 16px;
    border: 1px solid #188AAE;
    border-radius: 30px;
    padding-left:100px;
}
input::-webkit-input-placeholder {
    font-size:16px;
    color:#9AE4FA;
  }
  input::-moz-placeholder {
    font-size:16px;
    color:#9AE4FA;
  }
  input::-ms-input-placeholder {
    font-size:16px;
    color:#9AE4FA;
  }
`

export const InputLabel = styled.div`
position:absolute;
z-index:100;
color:#1888AC;
font-size:16px;
top:50%;
transform:translateY(-50%);
left:60px;
`

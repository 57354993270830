import styled from "styled-components";
import { Row } from "../../../styled";

import { barWith } from "./ColorBar";

export const TitleWrap = styled.div`
  height: 50px;
  background-color: #eee;
  padding: 0 20px;
  font-weight: bolder;
  color: #000;
  font-size: 16px;
`;

export const ColorBarWrap = styled(Row)`
  width: ${barWith}+ "px";
  height: 50px;
  position: relative;
  z-index: 100;
`;

export const ArrowImg = styled.div<{ num: number }>`
  width: 22px;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: ${(props) => (props.num -10)+ "px"};
`;

export const Num = styled.div<{ num: number }>`
  position: absolute;
  bottom: -30px;
  left: ${(props) => (props.num-12) + "px"};
`;

export const Red = styled.div<{ percent: number }>`
  background-color: red;
  width: ${(props) => props.percent + "px"};
  height: 100%;
`;

export const Yellow = styled(Red)`
  background-color: yellow;
`;

export const Blue = styled(Red)`
  background-color: blue;
`;

export const Green = styled(Red)`
  background-color: green;
`;

import React, { FC } from "react";
import { BlockTitle, BlockItem, BlockWrap } from "../styled";


export interface IBlock {
    score: number
}
export const Block: FC<IBlock> = ({ score }) => {
    const renderData = [
        {
            name: '启蒙早期',
            subName: 'Early Emergent',
            min: 300,
            max: 487,
        },
        {
            name: '启蒙晚期',
            subName: 'Late Emergent',
            min: 488,
            max: 674,
        },
        {
            name: '过渡期',
            subName: 'Transitional Period',
            min: 675,
            max: 774,
        },
        {
            name: '开始阅读',
            subName: 'Reading Start',
            min: 775,
            max: 900,
        }
    ]
    return (
        <>
            <BlockTitle>阅读阶段</BlockTitle>
            <BlockWrap>
                {
                    renderData.map((v, i) => (
                        <BlockItem
                            key={i}
                            style={(score >= v.min && score <= v.max) ? {
                                background: '#1789AD',
                                color: '#fff'
                            } : {}}
                        >
                            <div>{v.name}</div>
                            <div>{v.subName}</div>
                        </BlockItem>
                    ))
                }
            </BlockWrap>
        </>

    )
}

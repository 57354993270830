import { post, postForm } from "../utils/rpc";
import { IRes } from "./pdfData";

interface ILoginRes extends IRes{
    redirect_url:string
}

export const login = (params: { phone_number: string, password: string }): Promise<ILoginRes> => {
    return postForm({
        url: "/login_user/", body: { ...params }
    });
};

export const reset = (params: { phone_number: string, password: string ,sms_code:string}): Promise<IRes> => {
    return post({
        url: "/passreset", body: { ...params }
    });
};


export const sendSms = ( phone_number: string): Promise<IRes> => {
    return post({
        url: "/sendsms", body: {phone_number }
    });
};

import { useEffect, useState } from "react";
import config from "../../../config";
import { fetchMapData } from "../../../services/pdfData";
import { getItem, StoreEnum } from "../../../utils/store";

export interface IMapData {
  url: string;
  rit: string;
  date: string;
  duration: string;
  // option1X: string[],
  // option1Y: string[],
  achievement_above_mean: string;
  flesch_kincaid_grade_level: string;
  grade: string;
  growth_goals_date: string;
  lexile_score: string;
  sub_domains_info: {
    domain_name: string;
    domain_score: string;
    focus_strength_info: string;
  }[];
  map_instructional_report_pdf_url: string;
  map_growth_pic_url: string;
}

export const useMapUrl = (type: string) => {
  const [loading, setLoading] = useState(false);

  const [mapData, setMapData] = useState<IMapData>({
    achievement_above_mean: "",
    flesch_kincaid_grade_level: "",
    grade: "",
    growth_goals_date: "",
    lexile_score: "",
    sub_domains_info: [],
    url: "",
    rit: "",
    date: "",
    duration: "",
    map_instructional_report_pdf_url: "",
    map_growth_pic_url: "",
    // option1X: [],
    // option1Y: [],
  });
  let phone = getItem(StoreEnum.PHONE);
  if (__DEV__) {
    phone = config.testPhone;
  }
  useEffect(() => {
    if (type) {
      setLoading(true);

      fetchMapData(phone, type)
        .then((res) => {
          setMapData({
            achievement_above_mean: res.achievement_above_mean,
            flesch_kincaid_grade_level: res.flesch_kincaid_grade_level,
            growth_goals_date: res.growth_goals_date,
            lexile_score: res.lexile_score,
            sub_domains_info: res.sub_domains_info,
            url: res.map_pdf_url,
            rit: res.rit_score,
            date: res.test_date,
            duration: res.test_duration,
            map_instructional_report_pdf_url:
              res.map_instructional_report_pdf_url,
            map_growth_pic_url: res.map_growth_pic_url,
            grade: res.grade,
            // option1X: res.map_score_trend_date,
            // option1Y: res.map_score_trend_value,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [type]);
  return {
    mapData,
    loading,
  };
};

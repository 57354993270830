import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import { Button, Input, message } from 'antd';
import {
  Wrap,
  Title,
  InputWrap,
  InputItem,
  Content,
  BtnWrap
} from "./styled";

import { handleSendSms,handleReset } from "./action";
import LoadingPage from "../../components/loading";
import { getCookieObj } from "../../utils/common";
import { getItem, setItem, StoreEnum } from "../../utils/store";
import { LockOutlined, SafetyCertificateOutlined, MobileOutlined } from '@ant-design/icons';
import { BackBtn, ButtonWithCode } from "../../components/Button";
import { useHistory } from "react-router";
import { CopyRight } from "../styled";

export default () => {

  const iconStyle = {
    color: '#1789AD', fontSize: '24px',
    marginRight: '8px'
  }

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [isDisabledPhone, setIsDisabledPhone] = useState(false);

  useEffect(() => {
    const phone_number = getItem(StoreEnum.PHONE);
    if (phone_number) {
      setInputData(pre => ({
        ...pre,
        phone_number
      }));
      setIsDisabledPhone(true);
    } else {
      setIsDisabledPhone(false);
    }

  }, [])

  const [inputData, setInputData] = useState({
    phone_number: '',
    password: '',
    confirmPassword: '',
    sms_code: ''
  })

  const inputArr = [
    {
      type: 'number',
      value: inputData.phone_number,
      placeholder: '请输入平台注册的手机号',
      disabled: isDisabledPhone,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        const value = e.target.value;
        if (value.length > 11) {
          setInputData(pre => ({
            ...pre,
            phone_number: value.slice(0, 11)
          }))
        } else {
          setInputData(pre => ({
            ...pre,
            phone_number: value.slice(0, 11)
          }))
        }
      },
      // addonBefore: <><MobileOutlined style={{ ...iconStyle, marginRight: 0 }} />+86</>,
      prefix: <MobileOutlined style={iconStyle} />,
    },
    {
      type: 'number',
      value: inputData.sms_code,
      placeholder: '请输入短信验证码',
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        const value = e.target.value;
        if (value.length > 6) {
          setInputData(pre => ({
            ...pre,
            sms_code: value.slice(0, 6)
          }))
        } else {
          setInputData(pre => ({
            ...pre,
            sms_code: value.slice(0, 6)
          }))
        }
      },
      prefix: <SafetyCertificateOutlined style={iconStyle} />,
      children:
        <ButtonWithCode
          style={{
            position: 'absolute',
            right: 0,
            height: '40px',
            background: '#1789AD',
            color: '#fff',
            zIndex: 100
          }}
          text='发送验证码'
          onPress={async () => {
            const res =await handleSendSms(inputData.phone_number);
            return res&&res.executed;
          }}
          initCode={60}
        />
    },
    {
      type: 'password',
      value: inputData.password,
      placeholder: '请输入新密码',
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setInputData(pre => ({
          ...pre,
          password: e.target.value
        }))
      },
      prefix: <LockOutlined style={iconStyle} />,
    },
    {
      type: 'password',
      value: inputData.confirmPassword,
      placeholder: '请确认新密码',
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setInputData(pre => ({
          ...pre,
          confirmPassword: e.target.value
        }))
      },
      prefix: <LockOutlined style={iconStyle} />,
    }
  ];

  const resetAction = async () => {
    setLoading(true);
    const res = await handleReset(inputData);
    setLoading(false);
    if (res && res.executed && res.success) {
      message.success(res.message, 2, () => {
        setItem(StoreEnum.SESSION, '');
        setItem(StoreEnum.PHONE, '');
        history.push('/login');
      });
    }
  }

  return (
    <Wrap>
      <BackBtn style={{
        position: 'absolute',
        top: '30px',
        left:'30px'
      }}/>
      <Content>
        <Title>手机号重置密码</Title>
        <InputWrap>
          {
            inputArr.map(({ children, ...v }, i) => (
              <Fragment key={i}>
                <InputItem
                  size="large"

                  {...v}
                />
                {children}
              </Fragment>
            ))
          }
        </InputWrap>
        <BtnWrap>
          <Button
            className='reset'
            onClick={async () => await resetAction()}
          >
            重置密码
          </Button>
          {/* <Button
            onClick={async () => await loginAction()}
          >
            返回
          </Button> */}
        </BtnWrap>


      </Content>
      <LoadingPage isLoading={loading} />
      <CopyRight style={{position:'absolute',bottom:20,left:'50%',transform:'translate(-50%)'}}>版权所有©2021 上海汤姆森信息技术有限公司©沪ICP备2020035728号</CopyRight>
    </Wrap>
  )
};

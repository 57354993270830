import { message } from "antd";
import { reset, sendSms } from "../../services/login";
import { validatePhone,validateNum } from "../../utils/validate";

interface resetParams{
    phone_number: string;
    password: string;
    sms_code: string;
    confirmPassword:string
}

export const handleReset = async (params:resetParams ) => {
    const phoneTemp = validatePhone(params.phone_number);
    if (!phoneTemp.state) {
        message.error(phoneTemp.msg);
        return false;
    }
    const smsCodeTemp = validateNum(params.sms_code);
    if (!smsCodeTemp.state) {
        message.error(smsCodeTemp.msg);
        return false;
    }
    if (!params.password) {
        message.error('请输入密码');
        return false;
    }
    if (!params.confirmPassword) {
        message.error('请输入确认新密码');
        return false;
    }
    if (params.confirmPassword!==params.password) {
        message.error('两次密码输入不一致');
        return false;
    }
    return await reset(params);
}

export const handleSendSms = async ( phone_number: string) => {
    const phoneTemp = validatePhone(phone_number);
    if (!phoneTemp.state) {
        message.error(phoneTemp.msg);
        return false;
    }
    return await sendSms(phone_number);
}
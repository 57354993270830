
import { getItem, StoreEnum } from "./store";
import { message } from "antd";
import { stringify } from "query-string";

const { requestPrefix } = require('../config');
async function request({
  url,
  options
}: {
  url: string;
  options: any;
}) {
  const uri = requestPrefix + url;
  console.log(`request uri ${uri}`);
  const _options: any = options || {};
  _options.method = _options.method || "GET";
  _options.headers = _options.headers || {};
  const session = getItem(StoreEnum.SESSION);
  if (session) {
    _options.headers["X-CSRFToken"] = session;
  }
  _options.credentials = "include";
  console.log("options: ", _options);
  try {
    const response = await fetch(uri, _options);
    if (response.status > 299) {
      message.error("请求失败，请联系管理员",3);
      return {
        executed: false,
      };
    }
    console.log("response", response);
    const text = await response.text();
    const json = JSON.parse(text);
    if (json.errorCode === "400") {
      message.warn('无数据', 5);
      return;
    }
    if (json.executed && json.success) {
      console.log(json);
      return json;
    } else {
      message.error(json.message,3);
    }
    // if (json.code === "LOGIN_INVALID") {
    //   window.location.href = prefix + "/login";
    // } else {
    //   if (!withoutAlert) {
    //     Toast.info(json.message || json.msg);
    //   }
    // }
    return json;
  } catch (err) {
    console.log(err);
  }
}

export function get({
  url,
  options,
  data,
}: {
  url: string;
  data?: { [key: string]: any };
  options?: { [key: string]: any };
}) {
  const temp = data || {};

  return request({
    url: url + "?" + stringify(temp),
    options: {
      headers: {
        "Content-Type": "application/json",
      },
      ...options,
    },
  });
}

export function post({
  url,
  body,
  options,
}: {
  url: string;
  body?: { [key: string]: any };
  options?: { [key: string]: any };
}) {
  const temp = body || {};
  console.log(url);
  console.log("post body", temp);
  return request({
    url,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(temp),
      ...options,
    }
  });
}

export function delRequest({
  url,
  body,
  options,
}: {
  url: string;
  body?: { [key: string]: any };
  options?: { [key: string]: any };
}) {
  const temp = body || {};
  console.log(url);
  console.log("delete body", temp);
  return request({
    url,
    options: {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(temp),
      ...options,
    }
  });
}

export async function upload({
  url,
  data,
}: {
  url: string;
  data: { [key: string]: any };
}) {
  const body = new FormData();
  Object.keys(data).forEach((key) => {
    body.append(key, data[key]);
  });
  const options: RequestInit = {
    method: "POST",
    body,
    credentials: "include",
  };
  return request({ url, options });
}

export function postForm({
  url,
  body,
  options,
}: {
  url: string;
  body?: { [key: string]: any };
  options?: { [key: string]: any };
}) {
  const temp = body || {};
  console.log(url);
  console.log("post body", temp);
  return request({
    url,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: stringify(temp),
      ...options,
    }
  });
}

import { Input } from "antd";
import React, { useState, useEffect } from "react";
import { InputLabel, InputWrap } from "./loginInput.style";


interface ILoginInput {
    label: string,
    initValue: string,
    onChange: (value: string) => void,
    inputStyle?: {},
    labelStyle?: {},
    style?: {},
}

export const LoginInput: React.FC<ILoginInput> = ({
    label,
    initValue,
    onChange,
    inputStyle,
    labelStyle,
    style,
    ...other
}) => {

    const [value, setValue] = useState("");
    useEffect(() => {
        setValue(initValue);
        onChange && onChange(initValue);
    }, [initValue]);

    return (
        <InputWrap style={style || {}}>
            {
                label &&
                <InputLabel style={labelStyle || {}}>{label}</InputLabel>
            }
            <Input
                className='custom-input'
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                    setValue(e.target.value);
                }}
                {...other}
            />
        </InputWrap >
    )
}
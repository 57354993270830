export enum StoreEnum {
  SESSION = "SESSION", // 用户token
  PHONE = 'phone',  // 用户手机号
}
export const setItem = (key: StoreEnum, value: string) => {
  sessionStorage.setItem(key, value);
};

export const getItem = (key: StoreEnum) => {
  return sessionStorage.getItem(key) || "";
};

import styled from "styled-components";
import { FlexFill, CustomWrap, Column, Row } from '../styled';

export const Wrap = styled(Column)`

`
export const HeaderWrap = styled.div`
margin:0 85px;
height:120px;
display:flex;
justify-content:space-between;
align-items:center;
`

export const Logo = styled.div`
width:147px;
height:79px;
`

export const IconWrap = styled(Row)`
align-items:center;
cursor: pointer;
`

export const IconItem = styled(Row)`
align-items:center;
`

export const IconImg = styled.div`
width:48px;
height:48px;
margin-right:5px;
`

export const IconText = styled.div`
width:40px;
font-size:14px;
line-height:18px;
display:inline-block;
`

export const Content = styled.div`
flex:1;
background-image:url(${require('./assets/login_bg.png')});
background-size:100% 100%;
position:relative;
`

export const LoginWrap = styled(Row)`
flex-direction:column;
position:absolute;
left:65%;
top:50%;
transform:translateY(-50%);
align-items:center;
.ant-btn{
    height: 54px;
    padding: 4px 15px;
    font-size: 20px;
    border-radius: 30px;
    color: #fff;
    background: #1789AD;
    border:none;
    width:100%;
}

`

export const Title = styled.div`
color: #1789AD;
font-size:44px;
margin-bottom:20px;

`


export const InputWrap = styled.div`

`


import styled from "styled-components";

export const Title = styled.div`
font-size:24px;
font-weight:bolder;
color:#000;
margin-bottom:30px;
`

export const SubTitle = styled.div`
width:1100px;
font-size:20px;
color:#000;
margin-bottom:10px;
`

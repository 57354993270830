import React, { useState, useEffect, useRef } from "react";
import { Button as AntdButton } from "antd";
import Loading from "../loading";
import "./index.css";
import { useHistory } from "react-router";
import { BtnWrap } from "../../pages/styled";

export interface IButton {
  style?: any;
  onPress?: () => Promise<void>;
  text: string;
  grayBg?: boolean;
  disabled?: boolean;
}

interface IButtonWithCode {
  onPress: () => Promise<boolean>;
  style?: any;
  text?: string;
  initCode: number;
  isLoginButton?: boolean;
  grayBg?: boolean;
  textButton?: boolean;
}

interface IButtonText {
  text?: string;
  initCode: number;
  count: number;
  isLoginButton?: boolean;
}

const handleBtnText = ({
  text,
  count,
  isLoginButton,
  initCode,
}: IButtonText) => {
  if (isLoginButton) {
    if (count === initCode) {
      return "获取验证码";
    } else {
      return `${count}s 重新获取`;
    }
  } else {
    if (count === initCode) {
      return text;
    } else {
      return text + `(${count}s)`;
    }
  }
};

const useCount = (initCount: number) => {
  const [count, setCount] = useState(initCount);
  useEffect(() => {
    if (!!initCount && count !== initCount) {
      if (count === 0) {
        setCount(initCount);
      }
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [count]);
  return { count, setCount, canClick: count === initCount };
};

export const ButtonWithCode: React.FC<IButtonWithCode> = ({
  style,
  text,
  initCode,
  isLoginButton,
  grayBg,
  onPress,
  textButton,
}) => {
  const { count, setCount, canClick } = useCount(initCode);
  const [isLoading, setLoading] = useState(false);
  const isMounted = useRef(true);
  useEffect(() => {
    if (initCode === 5) {
      setCount(initCode - 1);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <AntdButton
      className={`${grayBg && "gray-btn"} ${textButton && "text-btn"}`}
      disabled={!canClick}
      loading={isLoading}
      style={style || {}}
      onClick={async () => {
        if (canClick && !isLoading) {
          setLoading(true);
          const res = await onPress();
          setLoading(false);
          if (res && isMounted.current) {
            if (initCode !== 5) {
              setCount(initCode - 1);
            }
          }
        }
      }}
    >
      {handleBtnText({ text, initCode, count, isLoginButton })}
    </AntdButton>
  );
};

export const Button: React.FC<IButton> = ({
  style,
  onPress,
  text,
  grayBg,
  disabled,
}) => {
  const [isLoading, setLoading] = useState(false);
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <>
      <AntdButton
        style={style || {}}
        disabled={disabled}
        onClick={async () => {
          try {
            setLoading(true);
            if (!isLoading && onPress) {
              await onPress();
            }
            if (isMounted.current) {
              setLoading(false);
            }
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {text}
      </AntdButton>
      <Loading isLoading={isLoading} />
    </>
  );
};

export const BackBtn = ({ style = {} }: { style?: {} }) => {
  const history = useHistory();
  return (
    <BtnWrap>
      <Button
        text="返回"
        style={{ width: "120px", height: "42px", fontSize: "18px", ...style }}
        onPress={async () => {
          history.goBack();
        }}
      />
    </BtnWrap>
  );
};

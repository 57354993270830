import { useEffect, useState } from "react";
import {
  fetchStarReadingData,
  IItem,
  IReportDetail,
  IStarReading,
} from "../../../services/pdfData";
import { getItem, StoreEnum } from "../../../utils/store";
const config = require("../../../config");
interface IStarReadingData {
  district_benchmark: number[];
  grade: string;
  information_text_scores: IItem[];
  language_scores: IItem[];
  literature_scores: IItem[];
  main_pdf_url: string;
  report_details: IReportDetail[];
  simple_pdf_url: string;
  star_reading_scores: IStarReading[];
  test_duration: string;
  test_date: string;
}

export const useStarReadingData = (type: string) => {
  const [loading, setLoading] = useState(false);
  const [starReadingData, setStarReadingData] = useState<IStarReadingData>({
    district_benchmark: [],
    grade: "",
    information_text_scores: [],
    language_scores: [],
    literature_scores: [],
    main_pdf_url: "",
    report_details: [],
    simple_pdf_url: "",
    star_reading_scores: [],
    test_duration: "",
    test_date: "",
  });
  useEffect(() => {
    let phone = getItem(StoreEnum.PHONE);
    if (__DEV__) {
      phone = config.testPhone;
    }
    if (type) {
      setLoading(true);
      fetchStarReadingData(phone, type)
        .then((res) => {
          if (res && res.executed) {
            setStarReadingData({
              district_benchmark: res.district_benchmark,
              grade: res.grade,
              information_text_scores: res.information_text_scores,
              language_scores: res.language_scores,
              literature_scores: res.literature_scores,
              main_pdf_url: res.main_pdf_url,
              report_details: res.report_details,
              simple_pdf_url: res.simple_pdf_url,
              star_reading_scores: res.star_reading_scores,
              test_duration: res.test_duration,
              test_date: res.test_date,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [type]);

  return {
    loading,
    starReadingData,
  };
};

import { get, post } from "../utils/rpc";
import { IRes } from "./pdfData";

export interface ICoursesInfo {
    course_display_name: string,
    course_grade: string,
    course_recommend_level: string,
    course_highlight:string,
    course_price: string,
    course_image_url: string
}

export interface ICourseData {
    courses_info: ICoursesInfo[],
    item_name: string,
    courses_info_count: number,
    item_desc: string
}

interface IPictureRes extends IRes{
    reading_literature: ICourseData[],
    reading_foundational_skills: ICourseData[],
    writing: ICourseData[],
    count_of_develop_items: number[],
    speaking_listening: ICourseData[],
    language_standards: ICourseData[],
    reading_standards_informational_text:ICourseData[],
}

export const fetchIPictureData = ( phone_number: string): Promise<IPictureRes> => {
    return get({
        url: `/i_map/${phone_number}`
    });
};

import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export function dotStr(str: any) {
  return String(str).replace(/(\d)(?=(\d{3})+$)/g, "$1,");
}

//展示手机号
export const formatPhone = (phone: string) => {
  if (phone) {
    return phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
  } else {
    return '';
  }

};

export const useScrollTop = () => {
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      if (document.body.scrollTop || document.documentElement.scrollTop > 0) {
        window.scrollTo(0, 0);
      }
    });
  }, [history]);
};


export const handleBackCard = (backCard: string) => {
  const reg = /(\d{4})(?=\d)/g;
  if (backCard) {
    return backCard.replace(reg, "$1 ");
  } else {
    return '';
  }
}

export function getCookieObj() {
  let cookie = document.cookie.split(";");
  let tempObj:any = {};
  for(let item of cookie){
    // join数组转为字符串 trim去除字符串两边的空白符
    let arr = item.split("=").join().trim().split(',');
    tempObj[arr[0]] =arr[1];
  }
  return tempObj
}

import styled from "styled-components";
import { Row } from "../../../styled";

export const Wrap = styled.div`
  .ant-list-bordered .ant-list-header,
  .ant-list-bordered .ant-list-item {
    padding: 0;
  }
`;

export const ScoreListTitle = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: bolder;
  margin-top: 10px;
`;

export const ListHeader = styled.div`
  background-color: #eee;
  font-size: 16px;
  font-weight: bolder;
  padding: 5px;
`;

export const ItemWrap = styled(Row)`
  width: 1100px;
  align-items: center;
  padding: 10px 0;
  span {
    display: inline-block;
    width: 100px;
    text-align: center;
  }
  > span:last-child {
    font-size: 15px;
  }
  div {
    flex: 1;
    word-wrap: break-word;
    line-height: 30px;
  }
`;

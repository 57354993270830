import { get } from "../utils/rpc";

export interface IRes {
  errorCode: string;
  executed: boolean;
  message: string;
  success: boolean;
}

interface fetchTestListRes extends IRes {
  ccss_tests: string[];
  star_early_tests: string[];
  star_reading_tests: string[];
}

export const fetchTestList = (phone: string): Promise<fetchTestListRes> => {
  return get({
    url: "/my_test_list/" + phone,
  });
};

export interface fetchMapDataRes extends IRes {
  achievement_above_mean: string;
  flesch_kincaid_grade_level: string;
  grade: string;
  growth_goals_date: string;
  lexile_score: string;
  sub_domains_info: {
    domain_name: string;
    domain_score: string;
    focus_strength_info: string;
  }[];
  map_pdf_url: string;
  // map_score_trend_date: string[],
  // map_score_trend_value:string[],
  map_instructional_report_pdf_url: string;
  rit_score: string;
  test_date: string;
  test_duration: string;
  map_growth_pic_url: string;
}

export const fetchMapData = (
  phone: string,
  type: string
): Promise<fetchMapDataRes> => {
  return get({
    url: `/map_stats/${phone}/${type}`,
  });
};

export interface fetchStarEarlyDataRes extends IRes {
  lexile_measure: string;
  scaled_score: number;
  // sub_domain_score_trend_date: string[];
  // sub_domain_score_trend_value: number[][];
  sub_items_alphabetic_principle: number[];
  sub_items_other_domains: number[];
  sub_items_phonemic_awareness: number[];
  sub_items_phonics1: number[];
  sub_items_phonics2: number[];
  sub_items_structural_vocabulary: number[];
  test_date: string;
}

export const fetchStarEarlyData = (
  phone: string,
  type: string
): Promise<fetchStarEarlyDataRes> => {
  return get({
    url: `/scaledscores/${phone}/${type}`,
  });
};

export interface IItem {
  item_desc: string;
  item_score: number;
}

export interface IStarReading extends IItem {
  short_desc?: string;
}

export interface IReportDetail {
  title: string;
  data: IItem[];
}

export interface fetchStarReadingDataRes extends IRes {
  district_benchmark: number[];
  grade: string;
  information_text_scores: IItem[];
  language_scores: IItem[];
  literature_scores: IItem[];
  main_pdf_url: string;
  report_details: IReportDetail[];
  simple_pdf_url: string;
  star_reading_scores: IStarReading[];
  test_duration: string;
  test_date: string;
}

export const fetchStarReadingData = (
  phone: string,
  type: string
): Promise<fetchStarReadingDataRes> => {
  return get({
    url: `/star_reading_report/${phone}/${type}`,
  });
};

import React, { useEffect, useMemo, useState } from "react";
import { Menu, Badge, Table } from "antd";
import {
  Content,
  RightWrap,
  Item,
  ItemWrap,
  ItemInfo,
  ItemRight,
  ItemSubTitle,
  ItemTitle,
} from "./styled";
import { useIPictureData } from "./action";
import LoadingPage from "../../components/loading";
import { BackBtn } from "../../components/Button";
import { CopyRight } from "../styled";
import {
  CaretRightOutlined,
  CaretDownOutlined,
  StarFilled,
} from "@ant-design/icons";
import { ICourseData, ICoursesInfo } from "../../services/IPicture";

const { SubMenu } = Menu;

const menuTitle = [
  {
    title: "语言",
    en: "language_standards",
  },
  {
    title: "阅读基础技能",
    en: "reading_foundational_skills",
  },
  {
    title: "阅读信息性文字",
    en: "reading_standards_informational_text",
  },
  {
    title: "文字阅读",
    en: "reading_literature",
  },
  {
    title: "口语和听力",
    en: "speaking_listening",
  },
  {
    title: "写作",
    en: "writing",
  },
];

const ExpandableItem = ({ data }: { data: ICoursesInfo[] }) => {
  return (
    <ItemWrap>
      {data.map((v, i) => (
        <Item key={i}>
          <img src={v.course_image_url} alt="" />
          <ItemRight>
            <ItemTitle>{v.course_display_name}</ItemTitle>
            <ItemSubTitle>{v.course_highlight}</ItemSubTitle>
            <ItemInfo>
              <p>
                年级<span>{v.course_grade || "-"}</span>
              </p>
              <p>
                价格<span> {v.course_price || "-"}</span>
              </p>
              <p>
                推荐指数
                <span>
                  {Array(Number(v.course_recommend_level))
                    .fill(1)
                    .map((v, i) => (
                      <StarFilled key={i} style={{ color: "#1789AD" }} />
                    ))}
                </span>
              </p>
            </ItemInfo>
          </ItemRight>
        </Item>
      ))}
    </ItemWrap>
  );
};

export default () => {
  const { loading, countArr, data } = useIPictureData();
  const [courseData, setCourseData] = useState<ICourseData[]>([]);

  const [current, setCurrent] = useState(1);

  useEffect(() => {
    if (data) {
      setCourseData(data[menuTitle[0].en]);
    }
  }, [data]);

  const [selectedKeys, setSelectedKeys] = React.useState([menuTitle[0].en]);

  const handleClick = (e: any) => {
    setSelectedKeys([e.key]);
    setCurrent(1);
    if (data) {
      setCourseData(data[e.key]);
    }
  };

  const menuData = useMemo(() => {
    return menuTitle.map((v, i) => ({
      ...v,
      count: countArr[i],
    }));
  }, [countArr]);

  const columns = [
    {
      title: "序号",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 100,
    },
    {
      title: "描述",
      dataIndex: "item_desc",
      key: "item_desc",
      align: "center",
      width: 400,
      render: (item:string) => <div style={{textAlign:'left'}}>{item}</div>,
    },
    {
      title: "产品方案推荐",
      dataIndex: "courses_info_count",
      key: "courses_info_count",
      align: "center",
      width: 150,
    },
    {
      title: "查看详情",
      dataIndex: "",
      key: "x",
      render: (item: ICourseData) => item.courses_info.length === 0 && "-",
      width: 200,
      align: "center",
    },
  ];

  return (
    <div style={{ width: "100%", padding: 20, paddingBottom: 10 }}>
      <BackBtn />
      <Content style={{ marginTop: 20 }}>
        <Menu
          selectedKeys={selectedKeys}
          mode="inline"
          openKeys={["sub1"]}
          style={{ width: 300, fontSize: 20 }}
          onClick={handleClick}
        >
          <SubMenu key="sub1" title="我的待提升项">
            {menuData.map((v, i) => (
              <Menu.Item key={`${v.en}`}>
                {v.count > 0 ? (
                  <Badge count={v.count} offset={[15, -2]}>
                    <div>{v.title}</div>
                  </Badge>
                ) : (
                  <div>{v.title}</div>
                )}
              </Menu.Item>
            ))}
          </SubMenu>
        </Menu>
        <RightWrap>
          <Table
            style={{ minHeight: "650px" }}
            pagination={{
              defaultPageSize: 9,
              hideOnSinglePage: true,
              current: current,
              onChange: (page) => {
                setCurrent(page);
              },
            }}
            rowKey={(item) => item.item_name + selectedKeys[0]}
            columns={columns as any}
            expandable={{
              expandedRowRender: (record: ICourseData) => (
                <ExpandableItem data={record.courses_info} />
              ),
              rowExpandable: (record: ICourseData) =>
                record.courses_info.length !== 0,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (record.courses_info.length === 0) {
                  return null;
                } else {
                  if (expanded) {
                    return (
                      <CaretDownOutlined
                        onClick={(e) => onExpand(record, e)}
                        style={{ color: "#1789AD", fontSize: 24 }}
                      />
                    );
                  } else {
                    return (
                      <CaretRightOutlined
                        onClick={(e) => onExpand(record, e)}
                        style={{ color: "#1789AD", fontSize: 24 }}
                      />
                    );
                  }
                }
              },
            }}
            dataSource={courseData}
          />
        </RightWrap>
      </Content>
      <LoadingPage isLoading={loading} />
      <CopyRight>
        版权所有©2021 上海汤姆森信息技术有限公司©沪ICP备2020035728号
      </CopyRight>
    </div>
  );
};

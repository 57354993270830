import { useEffect, useState } from "react";
import { fetchIPictureData, ICourseData } from "../../services/IPicture";
import { getItem, StoreEnum } from "../../utils/store";


interface IData{
    [props:string]:ICourseData[]
}

export const useIPictureData = () => {
    const [loading, setLoading] = useState(true);

    const [countArr, setCountArr] = useState<number[]>([]);
    
    const [data, setData] = useState<IData>();
    const phone_number = getItem(StoreEnum.PHONE)||'15895901300';


    useEffect(() => {
        fetchIPictureData(phone_number).then((res) => {
            setCountArr(res.count_of_develop_items);
            setData({
                reading_literature: res.reading_literature.map((v,i)=>({...v,key:i+1})),
                reading_foundational_skills: res.reading_foundational_skills.map((v,i)=>({...v,key:i+1})),
                writing: res.writing.map((v,i)=>({...v,key:i+1})),
                speaking_listening: res.speaking_listening.map((v,i)=>({...v,key:i+1})),
                language_standards: res.language_standards.map((v,i)=>({...v,key:i+1})),
                reading_standards_informational_text:res.reading_standards_informational_text.map((v,i)=>({...v,key:i+1}))
            })
        }).finally(() => {
            setLoading(false);
        })
    }, []);
    
    return {
        loading,
        countArr,
        data,
    }
}


import React, { useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  pdfUrl: string;
  style?: {};
}

export const PdfView = (props: Props) => {
  const { pdfUrl, style } = props;
  const [numPages, setNumPages] = useState<number>(0);

  const pdfPage = useMemo(() => {
    const pageArr: number[] = [];
    for (let i = 1; i <= numPages; i++) {
      pageArr.push(i);
    }
    return pageArr;
  }, [numPages]);

  if (pdfUrl) {
    return (
      <div style={style || {}}>
        <Document
          file={pdfUrl}
          onLoadSuccess={({ numPages }) => {
            console.log("num", numPages);
            setNumPages(numPages);
          }}
        >
          {pdfPage.map((v, i) => (
            <Page width={1100} key={i} pageNumber={v} />
          ))}
        </Document>
      </div>
    );
  }
  return null;
};

import React, { FC, useMemo } from "react";
import { Row, RowCenter } from "../../../styled";
import { Title } from "../styled";
import {
  ArrowImg,
  Blue,
  ColorBarWrap,
  Green,
  Num,
  Red,
  TitleWrap,
  Yellow,
} from "./ColorBar.styled";

interface IColorBar {
  benchmark: number[];
  num: number;
  grade: string;
}

export const barWith = 1000;

// 13->10  13*10/13

const getWith = (number: number) => {
  if (isNaN(number)) {
    return 0;
  }
  return Math.floor(number * 1000);
};

export const ColorBar: FC<IColorBar> = ({ benchmark, num, grade }) => {
  const numArr = useMemo(
    () => [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300],
    []
  );

  const demoColor = useMemo(
    () => [
      {
        color: "red",
        text: "Urgent Intervention",
      },
      {
        color: "yellow",
        text: "Intervention",
      },
      {
        color: "blue",
        text: "On Watch",
      },
      {
        color: "green",
        text: "At/Above Benchmark",
      },
    ],
    []
  );

  const renderContent = () => {
    if (grade === "K") {
      return (
        <Title style={{marginBottom:0,width:'1100px'}}>No benchmark data available for this student or grade.</Title>
      );
    } else {
      return (
        <div>
          <Title>District Benchmark,Grade&nbsp;{grade}</Title>;
          <Row>
            <TitleWrap>
              Most Recent
              <br />
              Test
            </TitleWrap>
            <ColorBarWrap>
              <Red percent={getWith(benchmark[0])} />
              <Yellow percent={getWith(benchmark[1])} />
              <Blue percent={getWith(benchmark[2])} />
              <Green percent={getWith(benchmark[3])} />
              <ArrowImg num={(num * 10) / 13}>
                <img src={require("./assets/arrow.jpg")} alt="" />
              </ArrowImg>
              {numArr.map((v, i) => (
                <Num key={i} num={(v * 10) / 13}>
                  {v}
                </Num>
              ))}
            </ColorBarWrap>
          </Row>
          <Row style={{ marginTop: 40 }}>
            {demoColor.map((v, i) => (
              <RowCenter style={{ margin: "0 8px" }} key={i}>
                <span
                  style={{
                    backgroundColor: v.color,
                    width: 18,
                    height: 18,
                    display: "inline-block",
                    borderRadius: 5,
                    marginRight: 3,
                  }}
                />
                {v.text}
              </RowCenter>
            ))}
          </Row>
        </div>
      );
    }
  };

  return renderContent();
};

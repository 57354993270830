import { createRef } from "react";


export const routerRef = createRef<any>();

export const toLogin = () => {
  if (routerRef.current) {
    routerRef.current.history.replace("/login");
  }
};

export const getHistory = () => {
  return routerRef.current.history
};

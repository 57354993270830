import { Table } from "antd";
import React, { FC } from "react";
import { TableTitle, TableWrap } from "../styled";

const handleBg = (value: any) => {
    if (isNaN(value)) {
        return '';
    }
    if (value >= 80) {
        return 'green'
    }
    if (value < 60) {
        return 'red'
    }
    return 'yellow';
}

interface ITable {
    data: number[],
    tableCellTitle: string[],
    tempColumns: any[],
    title: string
}

export const CustomTable: FC<ITable> = ({ data, tempColumns, tableCellTitle, title }) => {


    const columns = tempColumns.map((v, i) => ({
        ...v,
        dataIndex: tableCellTitle[i],
        key: tableCellTitle[i],
        align: 'center' as 'center',
        width: 120,
        render: (value: number) => {
            return (
                <div className={`my-ant-table-cell ${handleBg(value)}`}>{value}</div>
            )
        }
    }));

    const titleObj: any = { key: '1' };
    tableCellTitle.forEach(v => {
        titleObj[v] = v;
    });

    const dataObj: any = { key: '2' };
    tableCellTitle.forEach((v, i) => {
        dataObj[v] = data[i];
    });
    return (
        <TableWrap>
            <TableTitle>{title}</TableTitle>
            <Table
                pagination={false}
                bordered
                dataSource={[titleObj, dataObj]}
                columns={
                    columns
                }
            />
        </TableWrap>

    )

}
import React, { useEffect, useMemo } from "react";
import { Menu } from "antd";
import { Content, RightWrap, Title } from "./styled";
import { BackBtn } from "../../components/Button";
import { CopyRight } from "../styled";
import { StarEarly } from "./starEarly";
import { Map } from "./CCSS";
import { StarReading} from './StarReading';
import { useListData } from "./action";
import LoadingPage from "../../components/loading";
const { SubMenu } = Menu;

const rootSubMenuKeys = ["sub1", "sub2", "sub3"];

export default () => {
  const [openKeys, setOpenKeys] = React.useState(["sub1"]);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [currentType, setCurrentType] = React.useState<string>("");

  const { loading, list } = useListData();

  const menuData = useMemo(() => {
    return [
      {
        title: "CCSS Test",
        key: "sub1",
        data: list.ccss_tests,
      },
      {
        title: "Star Reading Test",
        key: "sub2",
        data: list.star_reading_tests,
      },
      {
        title: "Star Early Test",
        key: "sub3",
        data: list.star_early_tests,
      },
    ];
  }, [list]);

  useEffect(() => {
    if (!loading) {
      for (let v of menuData) {
        if (v.data.length > 0) {
          setSelectedKeys([`${v.data[0]}_${v.title}`]);
          setOpenKeys([v.key]);
          setCurrentType(v.title);
          return;
        }
      }
    }
  }, [loading]);

  const onOpenChange = (keys: any) => {
    console.log(keys);
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleClick = (e: any) => {
    setCurrentType(e.key.split("_")[1]);
    setSelectedKeys([e.key]);
  };

  const rightContent = useMemo(() => {

    if (currentType === "CCSS Test") {
      return <Map type={selectedKeys[0].split("_")[0]} />;
    }
    if (currentType === "Star Early Test") {
      return <StarEarly type={selectedKeys[0].split("_")[0]} />;
    }
    if (currentType === "Star Reading Test") {
      return <StarReading type={selectedKeys[0].split("_")[0]} />
    }
    return <div>暂无数据</div>;
  }, [currentType,selectedKeys]);

  return (
    <div style={{ width: "100%", padding: 20, paddingBottom: 10 }}>
      <Content>
        <Menu
          selectedKeys={selectedKeys}
          mode="inline"
          openKeys={openKeys}
          style={{ width: 250, fontSize: 18 }}
          onOpenChange={onOpenChange}
          onClick={handleClick}
        >
          <BackBtn />
          <Title>测评足迹</Title>
          {menuData.map((v) => (
            <SubMenu key={v.key} title={v.title}>
              {v.data?.map((item) => (
                <Menu.Item key={`${item}_${v.title}`}>{item}</Menu.Item>
              ))}
            </SubMenu>
          ))}
        </Menu>
        <RightWrap>{rightContent}</RightWrap>
      </Content>
      <CopyRight>
        版权所有©2021 上海汤姆森信息技术有限公司©沪ICP备2020035728号
      </CopyRight>
      <LoadingPage isLoading={loading} />
    </div>
  );
};

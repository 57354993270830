import React, { useMemo } from "react";
import { FlexCenter, Row, RowCenter } from "../../../styled";
import {
  Wrap,
  ItemWrap,
  ArrowImg,
  ArrowSquare,
  ItemSubTitle,
  ItemTitle,
  LineTitle,
  CenterWrap,
  CenterItemTitle,
  BottomBolder,
  BottomWrap,
  BottomContent,
  ItemList,
  ItemNum,
  ItemText,
  ItemListItem,
  ItemListContent,
  ItemListExtra,
  ItemListExtraImg,
  ItemExtraText,
  ExtraTitle,
} from "./MapTable.styled";

interface IMapTable {
  achievement_above_mean: string;
  flesch_kincaid_grade_level: string;
  growth_goals_date: string;
  lexile_score: string;
  sub_domains_info: {
    domain_name: string;
    domain_score: string;
    focus_strength_info: string;
  }[];
}

export const ArrowTips = ({ num }: { num: number }) => {
  const colorArr = useMemo(
    () => [
      {
        bg: "#e3333d",
        min: 0,
        max: 19,
      },
      {
        bg: "#f46f30",
        min: 20,
        max: 39,
      },
      {
        bg: "#e9a92c",
        min: 40,
        max: 59,
      },
      {
        bg: "#59b12c",
        min: 60,
        max: 79,
      },
      {
        bg: "#167bd3",
        min: 80,
        max: 100,
      },
    ],
    []
  );
  return (
    <Row>
      {colorArr.map((v, i) => (
        <div key={i}>
          {
            <ArrowImg>
              {num >= v.min && num <= v.max && (
                <img src={require("./assets/icon_arrow.jpg")} alt="" />
              )}
            </ArrowImg>
          }
          <ArrowSquare style={{ backgroundColor: v.bg }}></ArrowSquare>
        </div>
      ))}
    </Row>
  );
};

const MapTable1: React.FC<{
  achievement_above_mean: string;
  flesch_kincaid_grade_level: string;
  lexile_score: string;
}> = ({ achievement_above_mean, flesch_kincaid_grade_level, lexile_score }) => {
    const num = achievement_above_mean?.replace(/[^\d]/g, "");
  return (
    <ItemWrap>
      <ItemTitle>COMPARISONS</ItemTitle>
      <ItemSubTitle>GROWTH & ACHIEVEMENT MEASURES</ItemSubTitle>
      <LineTitle>Norma Percentile</LineTitle>
      <CenterWrap>
        <div>
          <CenterItemTitle>GROWTH</CenterItemTitle>
          <p>- -</p>
          <p>No growth tests available</p>
        </div>
        <div>
          <CenterItemTitle>ACHIEVEMENT</CenterItemTitle>
          <p>above Mean</p>
          <p>{achievement_above_mean}</p>
        </div>
      </CenterWrap>
      <CenterWrap>
        <div />
        <FlexCenter>
          <ArrowTips num={Number(num)} />
        </FlexCenter>
      </CenterWrap>

      <LineTitle>Norma Percentile</LineTitle>
      <div style={{ textAlign: "center" }}>
        <p>- -</p>
        <p>No growth tests available</p>
      </div>
      <BottomWrap>
        <BottomBolder>PROJECTIONS</BottomBolder>
        <div>No projections available</div>
      </BottomWrap>
      <BottomWrap>
        <BottomBolder>READABILITY MEASURES</BottomBolder>
        <BottomContent>
          <div>
            <p>Lexile*</p>
            <BottomBolder>{lexile_score || "-"}</BottomBolder>
          </div>
          <div style={{ marginLeft: 60 }}>
            <p>Flesh-Kincaid Grade Level</p>
            <BottomBolder>{flesch_kincaid_grade_level || "-"}</BottomBolder>
          </div>
        </BottomContent>
      </BottomWrap>
    </ItemWrap>
  );
};

export const MapTable: React.FC<IMapTable> = ({
  achievement_above_mean,
  flesch_kincaid_grade_level,
  growth_goals_date,
  lexile_score,
  sub_domains_info,
}) => {
  return (
    <Wrap>
      <MapTable1
        achievement_above_mean={achievement_above_mean}
        flesch_kincaid_grade_level={flesch_kincaid_grade_level}
        lexile_score={lexile_score}
      />

      <ItemWrap>
        <ItemTitle>INSTRUCTIONAL AREAS</ItemTitle>
        <ItemList>
          {sub_domains_info?.map((v, i) => (
            <ItemListItem key={i}>
              <ItemListContent>
                <ItemNum>{v.domain_score}</ItemNum>
                <ItemText>{v.domain_name}</ItemText>
              </ItemListContent>
              {v.focus_strength_info && (
                <ItemListExtra>
                  <ItemListExtraImg>
                    <img src={require("./assets/icon1.jpg")} alt="" />
                  </ItemListExtraImg>
                  <div>{v.focus_strength_info}</div>
                </ItemListExtra>
              )}
            </ItemListItem>
          ))}
        </ItemList>
      </ItemWrap>
      <ItemWrap>
        <ItemTitle>GROWTH GOALS</ItemTitle>
        <ItemSubTitle>{growth_goals_date}</ItemSubTitle>
        <div>
          <RowCenter>
            <FlexCenter style={{ width: "25%" }}>
              <ItemListExtraImg>
                <img src={require("./assets/icon2.jpg")} alt="" />
              </ItemListExtraImg>
            </FlexCenter>
            <ItemExtraText>
              Customize the growth target for this student by setting a growth
              goal
            </ItemExtraText>
          </RowCenter>

          <ExtraTitle>Past Goal</ExtraTitle>
          <p>There are no previous goals for this student.</p>
        </div>
      </ItemWrap>
    </Wrap>
  );
};

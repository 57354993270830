import { message } from "antd";
import { login } from "../../services/login";
import { validatePhone } from "../../utils/validate";



export const handleLogin = async (params: { phone_number: string; password: string; }) => {
    const phoneTemp = validatePhone(params.phone_number);
    if (!phoneTemp.state) {
        message.error(phoneTemp.msg);
        return false;
    }
    if (!params.password) {
        message.error('请输入密码');
        return false;
    }
    return await login(params);
}
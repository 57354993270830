import styled from "styled-components";
import { CustomWrap, Row } from '../../styled';

export const HeaderWrap = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
flex-wrap:wrap;
width:1100px;
`
export const Title = styled.h2`
margin-top:20px;
font-size:30px;
font-weight:bolder;
text-align:center;
color:#1789AD;
`

export const BlockTitle = styled(Title)`
margin:0;
margin-bottom:20px;
font-size:30px;
`


export const HeaderItem = styled.div`
width:50%;
font-size:18px;
`
export const HeaderText = styled.span`
color:#1789AD;
font-size:28px;
margin-left:10px;
`

export const TableWrap = styled(Row)`
margin-bottom:30px;
align-items:center;
`

export const TableTitle = styled(Row)`
color:#1789AD;
font-size:20px;
margin-right:10px;
width:120px;
`

export const Line = styled.div`
height:3px;
background-color:#eee;
margin:40px 0;
width:100%;
`

export const BlockWrap = styled(Row)`
justify-content:center;
`

export const BlockItem = styled.div`
width:200px;
height:100px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
font-size:16px;
background-color:#eee;
margin:0 40px;
`
export const SubTitle = styled.div`
margin-bottom:20px;
font-size:20px;
font-weight:bolder;
color:#1789AD;
text-align:center;
`
export const TypeTitle = styled(Title)`
margin-top:0px;
margin-left:50px;
`
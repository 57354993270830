import { useEffect, useState } from "react";
import { fetchStarEarlyData } from "../../../services/pdfData";
import { getItem, StoreEnum } from "../../../utils/store";
const config = require("../../../config");
export interface IData {
  scaledScore: number;
  tableData1: number[];
  tableData2: number[];
  tableData3: number[];
  tableData4: number[];
  tableData5: number[];
  tableData6: number[];
  // trendDate: string[],
  // trendValue: number[][],
  lexileMeasure: string;
  testDate: string;
}

export const useData = (type: string) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IData>({
    tableData1: [],
    tableData2: [],
    tableData3: [],
    tableData4: [],
    tableData5: [],
    tableData6: [],
    scaledScore: 0,
    lexileMeasure: "",
    // trendDate: [],
    // trendValue:[],
    testDate: "",
  });
  useEffect(() => {
    let phone = getItem(StoreEnum.PHONE);
    if (__DEV__) {
      phone = config.testPhone;
    }
    if (type) {
      setLoading(true);
      fetchStarEarlyData(phone, type)
        .then((res) => {
          if (res && res.executed) {
            setData({
              scaledScore: res.scaled_score,
              lexileMeasure: res.lexile_measure,
              testDate: res.test_date,
              tableData1: res.sub_items_alphabetic_principle,
              tableData2: res.sub_items_phonemic_awareness,
              tableData3: res.sub_items_phonics1,
              tableData4: res.sub_items_phonics2,
              tableData5: res.sub_items_structural_vocabulary,
              tableData6: res.sub_items_other_domains,
              // trendDate: res.sub_domain_score_trend_date,
              // trendValue:res.sub_domain_score_trend_value
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [type]);

  return {
    data,
    loading,
  };
};

export const tableCellTitle1 = [
    'Alphabetic Knowledge',
    'Alphabetic Sequence',
    'Letter Sounds',
    'Word length',
    'Word borders',
    'Letters and Words',
    'Letters',
    'Identification and Word Matching'
];

export const tableCellTitle2 = [
    'Rhyming and Word Families',
    'Blending Word Parts',
    'Blending Phonemes',
    'Initial and Final Phonemes',
    'Consonant Blends (PA)',
    'Medial Phoneme Discrimination',
    'Phoneme Isolation/Manipulation',
    'Phoneme Segmentation'
];

export const tableCellTitle3 = [
    'Short Vowel Sounds',
    'Initial Consonant Sounds',
    'Final Consonant Sounds',
    'Long Vowel Sounds',
    'Variant Vowel Sounds',
    'Consonant Blends (PH)'
];

export const tableCellTitle4 = [
    'Consonant Digraphs',
    'Other Vowel Sounds',
    'Sound-Symbol Correspondence: Consonants',
    'Word Building',
    'Sound-Symbol Correspondence: Vowels',
    'Word Families/Rhyming'
];

export const tableCellTitle5 = [
    'Words with Affixes',
    'Syllabification',
    'Compound Words',
    'Word Facility',
    'Synonyms',
    'Antonyms'
];

export const tableCellTitle6 = [
    'Comprehension at the Sentence Level',
    'Comprehension of Paragraphs',
    'Number Naming and Number Identification',
    'Number Object Correspondence',
    'Sequence Completion',
    'Composing and Decomposing',
    'Measurement'
];


export const tempColumns1 = [
    {
        title: 'Alphabetic Principle',
        colSpan: 3,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: 'Concept of Word',
        colSpan: 3,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: 'Visual Discrimination',
        colSpan: 2,
    },
    {
        title: '',
        colSpan: 0,
    },
];

export const tempColumns2 = [
    {
        title: 'Phonemic Awareness',
        colSpan: 8,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
];

export const tempColumns3 = [
    {
        title: 'Phonics',
        colSpan: 6,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
];

export const tempColumns4 = [
    {
        title: 'Phonics',
        colSpan: 6,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
];

export const tempColumns5 = [
    {
        title: 'Structural Analysis',
        colSpan: 3,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: 'Vocabulary',
        colSpan: 3,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
];

export const tempColumns6 = [
    {
        title: 'Sentence-Level Comprehension',
    },
    {
        title: 'Paragraph-Level Comprehension',
    },
    {
        title: 'Early Numeracy',
        colSpan: 5,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
    {
        title: '',
        colSpan: 0,
    },
];



import { useEffect, useState } from "react";
import config from "../../config";
import { fetchTestList } from "../../services/pdfData";
import { getItem, StoreEnum } from "../../utils/store";

interface IList {
  ccss_tests: string[];
  star_early_tests: string[];
  star_reading_tests: string[];
}

export const useListData = () => {
  const [loading, setLoading] = useState(true);

  const [list, setList] = useState<IList>({
    ccss_tests: [],
    star_early_tests: [],
    star_reading_tests: [],
  });
  let phone_number = getItem(StoreEnum.PHONE);
  if (__DEV__) {
    phone_number = config.testPhone;
  }

  useEffect(() => {
    fetchTestList(phone_number)
      .then((res) => {
        setList({
          ccss_tests: res.ccss_tests,
          star_early_tests: res.star_early_tests,
          star_reading_tests: res.star_reading_tests,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    loading,
    list,
  };
};

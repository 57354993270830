import styled from "styled-components";
import { Row } from "../../../styled";

export const ScoreListWrap = styled.div`
  width: 1100px;
`;

export const ScoreListTitle = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: bolder;
  margin-top: 10px;
`;

export const ListWrap = styled(Row)`
  flex-wrap: wrap;
`;

export const ScoreItemWrap = styled(Row)<{ bolder: boolean; index: number }>`
  border: 2px solid #000;
  padding: 10px 20px;
  width: 330px;
  margin: ${(props) => (props.index % 3 === 1 ? "10px 40px" : "10px 0px")};
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: ${(props) => (props.bolder ? "bold" : "normal")};
  > span {
    font-size: ${(props) => (props.bolder ? "18px" : "15px")};
    border: ${(props) => (props.bolder ? "2px solid #bbb" : "none")};
    padding: ${(props) => (props.bolder ? "2px 8px" : "0px")};
    border-radius: 50%;
  }
`;

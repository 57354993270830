import React, { useRef, useState } from "react";
import { Button } from 'antd';
import {
  Wrap,
  Content,
  HeaderWrap,
  Logo,
  IconWrap,
  IconItem,
  IconImg,
  IconText,
  LoginWrap,
  Title,
  InputWrap,
} from "./styled";

import { LoginInput } from "./LoginInput";
import { handleLogin } from "./action";
import LoadingPage from "../../components/loading";
import { getCookieObj } from "../../utils/common";
import { setItem, StoreEnum } from "../../utils/store";
import { CopyRight } from "../styled";



export default () => {

  const [loading, setLoading] = useState(false);

  const inputData = useRef({
    phone_number: '',
    password: ''
  })

  const iconArr = [
    {
      icon: require('./assets/icon_my.png'),
      text: '关于我们',
      onClick: () => {
        window.location.href = '/about'
      },
      color: '#1888AC'
    },
    // {
    //   icon: require('./assets/icon_phone.png'),
    //   text: '联系我们',
    //   onClick: () => {

    //   },
    //   color: '#EF8436'

    // },
    {
      icon: require('./assets/icon_arrow.png'),
      text: '登陆账号',
      onClick: async () => await loginAction(),
      color: '#188AAE'

    }
  ];

  const inputArr = [
    {
      label: '电话',
      maxLength: 11,
      initValue: inputData.current.phone_number,
      placeholder: 'username',
      onChange: (value: string) => {
        inputData.current.phone_number = value;
      },
      onKeyUp: (e:any) => {
        if (e.keyCode===13) {
          loginAction();
        }
      }
    },
    {
      label: '密码',
      type: 'password',
      initValue: inputData.current.password,
      placeholder: 'password',
      onChange: (value: string) => {
        inputData.current.password = value;
      },
      onKeyUp: (e:any) => {
        if (e.keyCode===13) {
          loginAction();
        }
      }
    }
  ];

  const loginAction = async () => {
    setLoading(true);
    const res = await handleLogin(inputData.current);
    setLoading(false);
    if (res && res.executed&&res.success) {
      const data = getCookieObj();
      setItem(StoreEnum.SESSION, data.csrftoken || '');
      setItem(StoreEnum.PHONE, data.user_phone_number || '');
      window.location.href = res.redirect_url
    }
  }


  return (
    <Wrap>
      <HeaderWrap>
        <Logo>
          <img src={require('./assets/logo.png')} alt="" />
        </Logo>
        <IconWrap>
          {
            iconArr.map((v, i) => (
              <IconItem onClick={v.onClick} key={i}>
                <IconImg>
                  <img src={v.icon} alt="" />
                </IconImg>
                <IconText style={{ color: v.color }}>
                  {
                    v.text
                  }
                </IconText>
              </IconItem>
            ))
          }
        </IconWrap>
      </HeaderWrap>

      <Content>
        <LoginWrap>
          <Title>Welcome</Title>
          <InputWrap>
            {
              inputArr.map((v, i) => (
                <LoginInput
                  key={i}
                  {...v}
                />
              ))
            }
          </InputWrap>
          <Button
            onClick={async () => await loginAction()}
          >
            立即登陆
            </Button>
        </LoginWrap>
      </Content>
      <LoadingPage isLoading={loading} />
      <CopyRight style={{marginBottom:10}}>版权所有©2021 上海汤姆森信息技术有限公司©沪ICP备2020035728号</CopyRight>
    </Wrap>
  )
};

import styled from "styled-components";
import { Row, RowCenter } from "../../../styled";

export const Wrap = styled(Row)`
  width: 1100px;
  justify-content: space-between;
  > div {
    border: 2px solid #1789ad;
    width: 32%;
  }
`;

export const ItemWrap = styled.div`
  padding: 10px;
`;

export const ItemTitle = styled.div`
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  color: #1789ad;
  margin-bottom: 10px;
`;

export const LineTitle = styled.div`
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 4px solid #c1cfcb;
  margin-top: 30px;
`;

export const ItemSubTitle = styled.div`
  font-size: 16px;
  font-weight: bolder;
  color: #000;
  margin-bottom: 10px;
`;

export const CenterWrap = styled(Row)`
  > div {
    width: 45%;
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
  }
`;

export const CenterItemTitle = styled.div`
  font-size: 16px;
`;

export const ArrowImg = styled.div`
  width: 20px;
  height: 20px;
`;

export const ArrowSquare = styled.div`
  width: 20px;
  height: 20px;
`;

export const BottomWrap = styled.div`
  padding-top: 5px;
  border-top: 2px solid #c1cfcb;
  margin-top: 10px;
`;

export const BottomBolder = styled.div`
  font-weight: bolder;
  font-size: 15px;
`;

export const BottomContent = styled(CenterWrap)`
  > div {
    text-align: left;
  }
`;

export const ItemList = styled.div``;

export const ItemListItem = styled.div`
  border-top: 2px solid #c1cfcb;
  padding: 30px 0;
  position: relative;
`;

export const ItemListContent = styled(RowCenter)`
  justify-content: center;
  font-weight: bolder;
`;

export const ItemNum = styled.span`
  font-size: 21px;
  width: 80px;
  text-align: center;
`;

export const ItemText = styled.span`
  flex: 1;
`;

export const ItemListExtra = styled(Row)`
  position: absolute;
  bottom: 5px;
  width: 100%;
  margin-left: 60px;
`;

export const ItemListExtraImg = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  top: -2px;
`;

export const ItemExtraText = styled.div`
  flex: 1;
`;

export const ExtraTitle = styled.div`
  margin-top: 30px;
  font-size: 16px;
  border-bottom: 2px solid #c1cfcb;
  text-align: center;
  margin-bottom: 10px;
`;

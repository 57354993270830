import styled from "styled-components";
import { FlexFill, CustomWrap, Column, Row,FlexCenter } from '../styled';
import { Button, Input } from 'antd';

export const Wrap = styled(FlexCenter)`
width:100%;
position:relative;

`

export const Content = styled.div`
transform:translateY(-5vh);
`

export const Title = styled.div`
color: #1789AD;
font-size:32px;
margin-bottom:40px;
text-align:center;
`

export const InputItem = styled(Input)`
margin-bottom:20px;
/* .ant-input,
.ant-input-group-addon{
  border-color:#1789AD;
}
.ant-input-affix-wrapper{
  border-color:#1789AD;
} */
/* flex-direction:column;
position:absolute;
left:65%;
top:50%;
transform:translateY(-50%);
align-items:center;
.ant-btn{
    height: 54px;
    padding: 4px 15px;
    font-size: 20px;
    border-radius: 30px;
    color: #fff;
    background: #1789AD;
    border:none;
    width:100%;
} */
`
export const InputWrap = styled.div`
width:20vw;
`
export const BtnWrap = styled.div`
margin-top:30px;
.ant-btn{
    height: 48px;
    padding: 4px 15px;
    font-size: 18px;
    border-radius: 3px;
    border:none;
    width:100%;
    margin-bottom:20px;
    border:1px solid #1789AD;
    &.reset{
        color: #fff;
        background: #1789AD;
        border:none;
    }
}
`




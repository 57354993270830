import React, { FC } from "react";
import { IStarReading } from "../../../../services/pdfData";
import {
  ListWrap,
  ScoreItemWrap,
  ScoreListTitle,
  ScoreListWrap,
} from "./ScoreList.styled";

interface IScoreItem extends IStarReading{
  bolder?: boolean;
  index: number;
}
interface IScoreList {
  data: IStarReading[];
  subTitle?: string;
  bolder?: boolean;
}

const ScoreItem: FC<IScoreItem> = ({
  item_desc,
  item_score,
  short_desc,
  bolder,
  index,
}) => {
  return (
    <ScoreItemWrap bolder={!!bolder} index={index}>
      <div>
        {item_desc}
        {short_desc && <div>({short_desc})</div>}
      </div>
      <span>{item_score}</span>
    </ScoreItemWrap>
  );
};

export const ScoreList: FC<IScoreList> = ({ data, subTitle, bolder }) => {
  return (
    <ScoreListWrap>
      {subTitle && <ScoreListTitle>{subTitle}</ScoreListTitle>}
      <ListWrap>
        {data.map((v, i) => (
          <ScoreItem key={i} {...v} bolder={bolder} index={i} />
        ))}
      </ListWrap>
    </ScoreListWrap>
  );
};

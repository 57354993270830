import styled from "styled-components";
import { Row } from '../styled';

export const Content = styled.div`
display:flex;
width:100%;
.ant-menu{
  .ant-menu-inline .ant-menu-item::after {
    border-right-color:#1789AD;
  }
  .ant-menu-submenu-selected{
    color:#1789AD;
  }
  .ant-menu-submenu{
    ul{
      li.ant-menu-item{
        line-height:48px;
        height:48px;
        .ant-badge,>div{
          font-size:18px;
        }
      }
    }
  }
}
`

export const RightWrap = styled.div`
flex:1;
padding:30px;
.ant-table-wrapper{
  .ant-table{
    font-size:16px;
    .ant-table-container{
      .ant-table-tbody{
        .ant-table-row{
          position:relative;
          .ant-table-row-expand-icon-cell{
            position:static;
            padding:0 !important;
            width:0 !important;
           .anticon-caret-down,.anticon-caret-right{
              position: absolute;
              z-index:100;
              right:10%;
              transform:translateY(-50%)
            }
          }
        }
      }
    }
  }
}
`


export const Title = styled.div`
color: #1789AD;
font-size:32px;
margin-bottom:40px;
text-align:center;
`

export const ItemWrap = styled.div`

`

export const Item = styled(Row)`
padding:10px 0;
border-bottom:1px solid #ddd;
/* justify-content:center; */
padding-left:50px;
align-items:center;
img{
  width:283px;
  height:145px;
}
&:last-child{
  border-bottom:none;
}
`

export const ItemRight = styled.div`
margin-left:100px;
font-size:18px;

`

export const ItemTitle = styled.div`
font-weight:bold;
`

export const ItemSubTitle = styled.div`
margin:10px 0;
`

export const ItemInfo = styled(Row)`
justify-content:space-between;
width:600px;
p{
  font-weight:bold;
  margin:0 5px;
  span{
    font-weight:normal;
    margin-left:10px;
  }
}
`

export const BtnWrap = styled.div`
margin-top:30px;
.ant-btn{
    height: 48px;
    padding: 4px 15px;
    font-size: 18px;
    border-radius: 3px;
    border:none;
    width:100%;
    margin-bottom:20px;
    border:1px solid #1789AD;
    &.reset{
        color: #fff;
        background: #1789AD;
        border:none;
    }
}
`




import React from "react";
import LoadingPage from "../../../components/loading";
import { FlexCenter } from "../../styled";
import { useData } from "./action";
import { Block } from "./component/Block";
import {
  tableCellTitle1,
  tempColumns1,
  tableCellTitle2,
  tempColumns2,
  tableCellTitle3,
  tempColumns3,
  tableCellTitle4,
  tempColumns4,
  tableCellTitle5,
  tempColumns5,
  tableCellTitle6,
  tempColumns6,
} from "./component/cellTitle";
import {
  Title,
  HeaderWrap,
  HeaderItem,
  HeaderText,
  Line,
  SubTitle,
  Wrap,
} from "./styled";
import { CustomTable } from "./component/table";

export const StarEarly: React.FC<{ type: string }> = ({ type }) => {
  const { data, loading } = useData(type);
  const headerArr = [
    {
      label: "测试标准",
      value: "CCSS Standard",
    },
    {
      label: "分数",
      value: data.scaledScore || "-",
    },
    {
      label: "蓝思值",
      value: data.lexileMeasure || "-",
    },
    {
      label: "测试日期",
      value: data.testDate || "-",
    },
  ];

  // const option = {
  //   color: ["#4cabce", "#007ac0", "#006699"],
  //   tooltip: {
  //     trigger: "axis",
  //     axisPointer: {
  //       type: "shadow",
  //     },
  //   },
  //   legend: {
  //     data: data.trendDate.map((v) => `${v}测评结果`),
  //     textStyle: {
  //       fontSize: 12,
  //     },
  //   },
  //   xAxis: [
  //     {
  //       type: "category",
  //       axisTick: { show: false },
  //       data: ["字母基础", "音素基础", "自然拼读", "词法基础"],
  //       axisLabel: {
  //         show: true,
  //         textStyle: {
  //           fontSize: 18,
  //         },
  //       },
  //     },
  //   ],
  //   yAxis: [
  //     {
  //       type: "value",
  //       axisLabel: {
  //         show: true,
  //         textStyle: {
  //           fontSize: 18,
  //         },
  //       },
  //     },
  //   ],
  //   series: [
  //     {
  //       name: `${data.trendDate[0]}测评结果`,
  //       type: "bar",
  //       barGap: 0,
  //       data: data.trendValue[0] || [],
  //     },
  //     {
  //       name: `${data.trendDate[1]}测评结果`,
  //       type: "bar",
  //       data: data.trendValue[1] || [],
  //     },
  //     {
  //       name: `${data.trendDate[2]}测评结果`,
  //       type: "bar",
  //       data: data.trendValue[2] || [],
  //     },
  //   ],
  // };

  return (
    <Wrap>
      <Title style={{ marginTop: 0 }}>早期阅读测试</Title>
      <FlexCenter>
        <HeaderWrap>
          {headerArr.map((v, i) => (
            <HeaderItem
              key={i}
              style={i % 2 === 0 ? {} : { textAlign: "right" }}
            >
              <span>{v.label}</span>
              <HeaderText style={i === 1 ? { fontWeight: "bolder" } : {}}>
                {v.value}
              </HeaderText>
            </HeaderItem>
          ))}
        </HeaderWrap>
      </FlexCenter>

      <Line></Line>

      <Block score={data.scaledScore} />

      {/* <Line></Line>

      <Title style={{ marginTop: 0, marginBottom: 20 }}>
        四大知识技能测评结果变化趋势
      </Title>
      <FlexCenter>
        <ReactEcharts
          option={option as echarts.EChartOption<echarts.EChartOption.Series>}
          theme="Imooc"
          style={{ height: "400px", width: "1200px" }}
        />
      </FlexCenter> */}

      <Line></Line>

      <SubTitle>
        注：各知识技能点评测状态，红色表示需要紧急干预，黄色表示需要进一步观察，绿色表示该知识技能点已掌握
      </SubTitle>

      <CustomTable
        data={data.tableData1}
        tableCellTitle={tableCellTitle1}
        tempColumns={tempColumns1}
        title="字母基础"
      />
      <CustomTable
        data={data.tableData2}
        tableCellTitle={tableCellTitle2}
        tempColumns={tempColumns2}
        title="音素基础"
      />
      <CustomTable
        data={data.tableData3}
        tableCellTitle={tableCellTitle3}
        tempColumns={tempColumns3}
        title="自拼基础"
      />
      <CustomTable
        data={data.tableData4}
        tableCellTitle={tableCellTitle4}
        tempColumns={tempColumns4}
        title="高阶自拼"
      />
      <CustomTable
        data={data.tableData5}
        tableCellTitle={tableCellTitle5}
        tempColumns={tempColumns5}
        title="词法基础"
      />
      <CustomTable
        data={data.tableData6}
        tableCellTitle={tableCellTitle6}
        tempColumns={tempColumns6}
        title="其他"
      />
      <LoadingPage isLoading={loading} />
    </Wrap>
  );
};

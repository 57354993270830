import { List } from "antd";
import React, { FC } from "react";
import { ItemWrap, ListHeader, Wrap } from "./DetailList.styled";

interface IDetailItem {
  item_desc: string;
  item_score: number;
}
interface IDetailList {
  data: IDetailItem[];
  title: string;
}

const Item: FC<{ item: IDetailItem; index: number }> = ({ item, index }) => {
  return (
    <ItemWrap>
      <span>{index + 1}</span>
      <div>{item.item_desc}</div>
      <span>{item.item_score}</span>
    </ItemWrap>
  );
};

export const DetailList: FC<IDetailList> = ({ data, title }) => {
  return (
    <Wrap>
      <List
        header={<ListHeader>{title}</ListHeader>}
        bordered
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <Item item={item} index={index} />
          </List.Item>
        )}
      />
    </Wrap>
  );
};
